import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createSlots as _createSlots, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = { class: "h-full dark:bg-neutral-800" };
const _hoisted_2 = { class: "box-border h-[70px] border-0 border-b border-solid border-neutral-200 dark:border-neutral-50/10" };
const _hoisted_3 = {
    key: 0,
    class: "h-full w-full flex justify-between items-center"
};
const _hoisted_4 = { class: "grow w-full flex items-center justify-center" };
const _hoisted_5 = {
    key: 0,
    class: "grow text-center"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
    key: 1,
    class: "flex items-center justify-center w-full h-full"
};
const _hoisted_8 = ["src"];
const _hoisted_9 = { class: "grow overflow-hidden" };
const _hoisted_10 = {
    key: 0,
    class: "flex flex-col gap-3 mt-6"
};
const _hoisted_11 = {
    key: 0,
    class: "absolute left-0 h-4 w-0.5 rounded-sm bg-[--c-primary-900] dark:bg-[--c-primary-200]"
};
const _hoisted_12 = { class: "grow" };
const _hoisted_13 = ["href", "target"];
const _hoisted_14 = { class: "grow" };
const _hoisted_15 = { class: "my-6 mx-4 space-y-3" };
const _hoisted_16 = { class: "text-base text-neutral-900 dark:text-neutral-300 mb-6" };
const _hoisted_17 = {
    key: 0,
    class: "absolute left-0 h-4 w-0.5 rounded-sm bg-[--c-primary-900] dark:bg-[--c-primary-200]"
};
const _hoisted_18 = { class: "grow" };
const _hoisted_19 = ["href", "target", "onClick"];
const _hoisted_20 = { class: "grow" };
const _hoisted_21 = {
    key: 1,
    id: "main-menu-otherside"
};
const _hoisted_22 = { class: "mt-6" };
const _hoisted_23 = { class: "pl-3 pr-6 flex flex-wrap gap-2" };
const _hoisted_24 = { class: "text-base" };
const _hoisted_25 = ["data-tip"];
const _hoisted_26 = ["data-tip"];
import { computed, provide, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useWindowSize } from '@vueuse/core';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue';
import { useAuthStore, useSystemStore, useGrupoDePermissoes } from '@/stores';
import UiButton from '@/components/ui/button.vue';
import UiIcon from '@/components/ui/icon.vue';
import SidebarItem from './sidebarItem.vue';
import DefaultMenuLogo from './defaultMenuLogo.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'sidebar',
    props: {
        maxWidth: {},
        minWidth: {},
        minimized: { type: Boolean }
    },
    emits: ['onMinimize'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const route = useRoute();
        const { width } = useWindowSize();
        const hasCrumb = (names) => route.meta.breadcrumbs?.length && names.every((name) => route.meta.breadcrumbs?.indexOf(name) !== -1);
        const systemStore = useSystemStore();
        const authStore = useAuthStore();
        const gpStore = useGrupoDePermissoes();
        const openItems = ref([]);
        provide('openItems', openItems);
        const items = computed(() => {
            return [
                {
                    name: 'learning',
                    title: 'menu.learning',
                    permission: 'Aprendizado',
                    icon: 'book',
                    to: '/aprendizado'
                },
                {
                    name: 'management',
                    title: 'menu.management',
                    permission: 'Gestão',
                    icon: 'chart-pie',
                    items: [
                        {
                            name: 'progress',
                            title: 'menu.myProgress',
                            permission: 'Meu Progresso',
                            to: '/gestao/progresso'
                        },
                        {
                            name: 'dashboard',
                            title: 'menu.dashboard',
                            permission: 'Dashboard',
                            to: '/gestao/dashboard'
                        },
                        {
                            name: 'bi-dashboard',
                            title: "Dashboard V2",
                            to: '/gestao/bi-dashboard',
                            permission: 'Dashboard v2'
                        },
                        {
                            name: 'my-panel',
                            title: 'menu.myPanel',
                            permission: 'Meu Painel',
                            to: '/gestao/meu-painel'
                        },
                        {
                            name: 'BI',
                            title: 'menu.bi',
                            permission: 'BI',
                            to: 'https://bi.plantareducacao.com.br/',
                            target: '_blank'
                        },
                        {
                            name: 'bi-management',
                            title: "Paineis Dashboard",
                            to: '/gestao/bi-management',
                            permission: 'Painéis Dashboard'
                        },
                        {
                            name: 'externalCertificates',
                            title: 'menu.externalCertificates',
                            permission: 'Certificados Externos',
                            to: '/gestao/certificados-externos'
                        },
                        // {
                        //   name: 'upload-history',
                        //   title: 'Hist. envios',
                        //   to: '/gestao/historico-envios'
                        // },
                        // {
                        //   name: 'import-history',
                        //   title: 'Hist. importações',
                        //   to: '/gestao/importacoes'
                        // }
                        {
                            name: 'assessments',
                            title: 'Avaliações de Comportamento',
                            to: '/gestao/avaliacao',
                        }
                    ].filter((el) => el.name === 'bi-management' && authStore.hasElevatedPrivileges ? true : gpStore.canUserAccess(el.permission, 'Gestão'))
                },
                {
                    name: 'account',
                    title: 'menu.accounts',
                    permission: 'Contas',
                    icon: 'user',
                    items: [
                        ...(authStore.inAdminMode ? [{
                                name: 'companies',
                                title: 'menu.companies',
                                permission: 'Empresas',
                                to: '/conta/pessoa',
                                meta: { permission: 'plantar' }
                            },
                            {
                                name: 'contracts',
                                title: 'menu.contracts',
                                permission: 'Contratos',
                                to: '/conta/contrato',
                                meta: { permission: 'plantar' }
                            },
                        ] : [{
                                name: 'my-contract',
                                title: 'menu.myContract',
                                permission: 'Meu Contrato',
                                to: '/conta/meu-contrato'
                            }]),
                        {
                            name: 'units',
                            title: 'menu.units',
                            permission: 'Unidades de Negócio',
                            to: '/conta/unidade'
                        },
                        {
                            name: 'departments',
                            title: 'menu.departments',
                            permission: 'Departamentos',
                            to: '/conta/departamento'
                        },
                        {
                            name: 'roles',
                            title: 'menu.jobsTitles',
                            permission: 'Cargos',
                            to: '/conta/cargo'
                        },
                        {
                            name: 'users',
                            title: 'menu.users',
                            permission: 'Usuários',
                            to: '/conta/usuario'
                        }
                    ]?.filter((el) => gpStore.canUserAccess(el.permission, 'Contas'))
                },
                {
                    name: 'content',
                    title: 'menu.content',
                    permission: 'Conteúdo',
                    icon: 'video',
                    items: [
                        {
                            name: 'depot',
                            title: 'menu.repository',
                            permission: 'Depósito',
                            to: '/content/files'
                        },
                        {
                            name: 'tests',
                            title: 'menu.tests',
                            permission: 'Avaliações',
                            to: '/conteudo/avaliacao'
                        },
                        {
                            name: 'courses',
                            title: 'menu.courses',
                            permission: 'Cursos',
                            to: '/conteudo/curso'
                        },
                        {
                            name: 'formations',
                            title: 'menu.trails',
                            permission: 'Trilhas',
                            to: '/conteudo/trilha'
                        },
                        {
                            name: 'sections',
                            title: 'menu.sections',
                            permission: 'Seções',
                            to: '/conteudo/secao'
                        },
                        {
                            name: 'manage-externalCertificates',
                            title: 'menu.manageExternalCertificates',
                            permission: 'Gerir Certificados Externos',
                            to: '/conteudo/certificados-externos-admin'
                        }
                    ].filter((el) => gpStore.canUserAccess(el.permission, 'Conteúdo'))
                },
                {
                    name: 'communications',
                    title: 'menu.communications',
                    permission: 'Comunicações',
                    icon: 'speakerphone',
                    items: [
                        {
                            name: 'communication',
                            title: 'menu.communication',
                            permission: 'Comunicação',
                            to: '/comunicacoes/comunicacao'
                        },
                        {
                            name: 'automated-notifications',
                            title: 'menu.automatedNotifications',
                            permission: 'Notificações Automatizadas',
                            to: '/comunicacoes/notificacoes-automatizadas'
                        }
                    ].filter((el) => gpStore.canUserAccess(el.permission, 'Comunicações'))
                },
                {
                    name: 'gamification',
                    title: 'menu.gamification',
                    permission: 'Gamificação',
                    icon: 'device-gamepad',
                    items: [
                        {
                            name: 'journey',
                            title: 'menu.journey',
                            permission: 'Jornada',
                            to: '/gamificacao/jornada'
                        },
                        {
                            name: 'challenges',
                            title: 'menu.challenges',
                            permission: 'Desafios',
                            to: '/gamificacao/desafios'
                        }
                    ].filter((el) => gpStore.canUserAccess(el.permission, 'Gamificação'))
                },
                {
                    name: 'settings',
                    title: 'menu.settings',
                    permission: 'Configurações',
                    icon: 'settings',
                    items: [
                        // {
                        //   name: 'general-parameters',
                        //   title: 'Parâmetros Gerais',
                        //   to: '/settings/contrato',
                        //   meta: { permission: 'plantar' }
                        // },
                        // {
                        //   name: 'contracts',
                        //   title: 'Contratos',
                        //   to: '/settings/contrato',
                        //   meta: { permission: 'plantar' }
                        // },
                        {
                            name: 'customization',
                            title: 'menu.customizations',
                            permission: 'Personalizações',
                            to: '/settings/personalizacoes'
                        },
                        {
                            name: 'competences',
                            title: 'menu.skills',
                            permission: 'Competências',
                            to: '/settings/competencia'
                        },
                        {
                            name: 'permission-groups',
                            title: 'menu.permissions',
                            permission: 'Permissões',
                            to: '/settings/permissoes'
                        },
                        {
                            name: 'categories',
                            title: 'menu.categories',
                            permission: 'Categorias',
                            to: '/settings/categoria'
                        },
                        {
                            name: 'certificates',
                            title: 'menu.certificates',
                            permission: 'Certificados',
                            to: '/settings/certificado'
                        },
                        {
                            name: 'terms-of-use',
                            title: 'menu.termsOfUse',
                            permission: 'Termos de Uso',
                            to: '/settings/termos-uso'
                        },
                        {
                            name: 'segmentations',
                            title: 'menu.segmentations',
                            permission: 'Segmentações',
                            to: '/settings/segmentacao'
                        }
                    ].filter((el) => gpStore.canUserAccess(el.permission, 'Configurações'))
                }
            ].filter((el) => typeof el.items === 'undefined' || el.items.length > 0).filter((el) => gpStore.canUserAccess(el.permission));
        });
        const logos = computed(() => systemStore.clientSettings.settings.logos);
        const isMobile = computed(() => width.value < 768);
        const widthStyle = computed(() => {
            const width = !props.minimized ? props.maxWidth || '260px' : props.minWidth || '80px';
            return typeof width === 'number' ? `${width}px` : width;
        });
        const toggleSize = () => {
            emit('onMinimize');
        };
        return (_ctx, _cache) => {
            const _component_RouterLink = _resolveComponent("RouterLink");
            const _directive_tooltip = _resolveDirective("tooltip");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", {
                    ref: "sidebarRef",
                    class: "box-border flex h-full flex-col border-0 border-r border-solid border-neutral-200 dark:border-neutral-50/10 bg-neutral-50 dark:bg-neutral-700/50 pb-6 transition-all duration-200",
                    style: _normalizeStyle({ width: widthStyle.value })
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(["box-border", _ctx.minimized ? 'px-5' : 'px-6'])
                    }, [
                        _createElementVNode("div", _hoisted_2, [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(!_ctx.$route.meta?.classRoom ? 'RouterLink' : 'span'), {
                                to: "/",
                                title: "Home"
                            }, {
                                default: _withCtx(() => [
                                    (!_ctx.minimized)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                            _createElementVNode("div", _hoisted_4, [
                                                (logos.value.loginLight)
                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                                        _createElementVNode("img", {
                                                            src: !_unref(systemStore).isDark ? logos.value.loginDark : logos.value.loginLight,
                                                            alt: "",
                                                            class: "object-contain h-full w-auto max-w-[200px] max-h-[42px]"
                                                        }, null, 8, _hoisted_6)
                                                    ]))
                                                    : (_openBlock(), _createBlock(DefaultMenuLogo, { key: 1 }))
                                            ]),
                                            _createVNode(UiButton, {
                                                class: "md:hidden h-10 w-10 bg-transparent hover:bg-neutral-500/20 !text-neutral-600 dark:!text-neutral-300 active:ring-0",
                                                onClick: _cache[0] || (_cache[0] = ($event) => (toggleSize()))
                                            }, {
                                                default: _withCtx(() => [
                                                    _createVNode(UiIcon, {
                                                        icon: "chevrons-left",
                                                        size: 24,
                                                        class: _normalizeClass(["transition-all", { '-scale-x-100': _ctx.minimized }])
                                                    }, null, 8, ["class"])
                                                ]),
                                                _: 1
                                            })
                                        ]))
                                        : (_openBlock(), _createElementBlock("span", _hoisted_7, [
                                            (logos.value.menu)
                                                ? (_openBlock(), _createElementBlock("img", {
                                                    key: 0,
                                                    src: logos.value.menu,
                                                    alt: "",
                                                    class: "w-6 h-6 object-cover"
                                                }, null, 8, _hoisted_8))
                                                : (_openBlock(), _createBlock(DefaultMenuLogo, {
                                                    key: 1,
                                                    compact: ""
                                                }))
                                        ]))
                                ]),
                                _: 1
                            }))
                        ])
                    ], 2),
                    _createElementVNode("div", _hoisted_9, [
                        _createVNode(_unref(OverlayScrollbarsComponent), {
                            defer: "",
                            class: "h-full text-neutral-500"
                        }, {
                            default: _withCtx(() => [
                                (!_ctx.$route.meta?.classRoom)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item, index) => {
                                            return (_openBlock(), _createBlock(SidebarItem, {
                                                key: index,
                                                icon: item.icon,
                                                name: _ctx.$t(item.title),
                                                items: item.items,
                                                to: item.to || '',
                                                class: "px-5",
                                                active: hasCrumb([item.name]),
                                                minimized: _ctx.minimized
                                            }, _createSlots({ _: 2 }, [
                                                (!_ctx.minimized && item.items)
                                                    ? {
                                                        name: "items",
                                                        fn: _withCtx(() => [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (subItem, subIndex) => {
                                                                return (_openBlock(), _createElementBlock(_Fragment, { key: subIndex }, [
                                                                    (!subItem?.target)
                                                                        ? (_openBlock(), _createBlock(_component_RouterLink, {
                                                                            key: 0,
                                                                            to: subItem.to,
                                                                            class: _normalizeClass(['sb-menu-item', { 'has-crumb': hasCrumb([item.name, subItem.name]) }])
                                                                        }, {
                                                                            default: _withCtx(() => [
                                                                                _createVNode(_Transition, {
                                                                                    appear: "",
                                                                                    mode: "out-in",
                                                                                    name: "scale-y-0"
                                                                                }, {
                                                                                    default: _withCtx(() => [
                                                                                        (hasCrumb([item.name, subItem.name]))
                                                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_11))
                                                                                            : _createCommentVNode("", true)
                                                                                    ]),
                                                                                    _: 2
                                                                                }, 1024),
                                                                                _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t(subItem.title)), 1)
                                                                            ]),
                                                                            _: 2
                                                                        }, 1032, ["to", "class"]))
                                                                        : (_openBlock(), _createElementBlock("a", {
                                                                            key: 1,
                                                                            href: subItem.to,
                                                                            target: subItem?.target,
                                                                            class: "sb-menu-item"
                                                                        }, [
                                                                            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t(subItem.title)), 1)
                                                                        ], 8, _hoisted_13))
                                                                ], 64));
                                                            }), 128))
                                                        ]),
                                                        key: "0"
                                                    }
                                                    : (_ctx.minimized && item.items)
                                                        ? {
                                                            name: "compactItems",
                                                            fn: _withCtx(({ close }) => [
                                                                _createElementVNode("div", _hoisted_15, [
                                                                    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t(item.title)), 1),
                                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (subItem, subIndex) => {
                                                                        return (_openBlock(), _createElementBlock("div", { key: subIndex }, [
                                                                            (!subItem?.target)
                                                                                ? (_openBlock(), _createBlock(_component_RouterLink, {
                                                                                    key: 0,
                                                                                    to: subItem.to,
                                                                                    class: _normalizeClass(['sb-menu-item !text-neutral-500 block !rounded-md', { 'has-crumb': hasCrumb([item.name, subItem.name]) }]),
                                                                                    onClick: () => close()
                                                                                }, {
                                                                                    default: _withCtx(() => [
                                                                                        _createVNode(_Transition, {
                                                                                            appear: "",
                                                                                            mode: "out-in",
                                                                                            name: "scale-y-0"
                                                                                        }, {
                                                                                            default: _withCtx(() => [
                                                                                                (hasCrumb([item.name, subItem.name]))
                                                                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_17))
                                                                                                    : _createCommentVNode("", true)
                                                                                            ]),
                                                                                            _: 2
                                                                                        }, 1024),
                                                                                        _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t(subItem.title)), 1)
                                                                                    ]),
                                                                                    _: 2
                                                                                }, 1032, ["to", "class", "onClick"]))
                                                                                : (_openBlock(), _createElementBlock("a", {
                                                                                    key: 1,
                                                                                    href: subItem.to,
                                                                                    target: subItem?.target,
                                                                                    class: "sb-menu-item !text-neutral-500 block !rounded-md",
                                                                                    onClick: () => close()
                                                                                }, [
                                                                                    _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t(subItem.title)), 1)
                                                                                ], 8, _hoisted_19))
                                                                        ]));
                                                                    }), 128))
                                                                ])
                                                            ]),
                                                            key: "1"
                                                        }
                                                        : undefined
                                            ]), 1032, ["icon", "name", "items", "to", "active", "minimized"]));
                                        }), 128))
                                    ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_21))
                            ]),
                            _: 1
                        })
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("div", _hoisted_23, [
                            _createVNode(_component_RouterLink, { to: "/" }, {
                                default: _withCtx(() => [
                                    (isMobile.value && _ctx.$route.meta?.classRoom)
                                        ? _withDirectives((_openBlock(), _createBlock(UiButton, {
                                            key: 0,
                                            type: "danger",
                                            outline: "",
                                            class: "py-2 px-2",
                                            title: _ctx.$t('button.leaveClassroom')
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(UiIcon, {
                                                    icon: "door-exit",
                                                    size: 20,
                                                    class: "-scale-x-100"
                                                }),
                                                _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t('button.exit')), 1)
                                            ]),
                                            _: 1
                                        }, 8, ["title"])), [
                                            [_directive_tooltip, { placement: 'bottom' }]
                                        ])
                                        : _createCommentVNode("", true)
                                ]),
                                _: 1
                            }),
                            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "grow" }, null, -1)),
                            _createElementVNode("div", {
                                class: "tooltip tooltip-right z-[51]",
                                "data-tip": _ctx.$t(!_unref(systemStore).isDark ? 'button.lightMode' : 'button.darkMode')
                            }, [
                                _createVNode(UiButton, {
                                    class: "h-10 w-10 bg-transparent hover:bg-neutral-500/20 !text-neutral-600 dark:!text-neutral-300 active:ring-0",
                                    onClick: _cache[1] || (_cache[1] = ($event) => (_unref(systemStore).toggleTheme()))
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(_Transition, {
                                            mode: "out-in",
                                            "enter-active-class": "transition ease-out duration-150 transform",
                                            "enter-from-class": "opacity-50 rotate-90",
                                            "leave-active-class": "ease-in duration-200",
                                            "leave-to-class": "opacity-50 -rotate-90"
                                        }, {
                                            default: _withCtx(() => [
                                                (!_unref(systemStore).isDark)
                                                    ? (_openBlock(), _createBlock(UiIcon, {
                                                        key: 0,
                                                        size: 24,
                                                        icon: "sun-high"
                                                    }))
                                                    : (_openBlock(), _createBlock(UiIcon, {
                                                        key: 1,
                                                        size: 24,
                                                        icon: "moon"
                                                    }))
                                            ]),
                                            _: 1
                                        })
                                    ]),
                                    _: 1
                                })
                            ], 8, _hoisted_25),
                            _createElementVNode("div", {
                                class: "hidden md:block tooltip tooltip-right z-50",
                                "data-tip": _ctx.$t(_ctx.minimized ? 'button.expand' : 'button.hide')
                            }, [
                                _createVNode(UiButton, {
                                    class: "h-10 w-10 bg-transparent hover:bg-neutral-500/20 !text-neutral-600 dark:!text-neutral-300 active:ring-0",
                                    onClick: _cache[2] || (_cache[2] = ($event) => (toggleSize()))
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(UiIcon, {
                                            icon: "chevrons-left",
                                            size: 24,
                                            class: _normalizeClass(["transition-all", { '-scale-x-100': _ctx.minimized }])
                                        }, null, 8, ["class"])
                                    ]),
                                    _: 1
                                })
                            ], 8, _hoisted_26)
                        ])
                    ])
                ], 4)
            ]));
        };
    }
});
