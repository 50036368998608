import baseApi from './base.api';
import BaseService from './base.service';
export const certificateService = {
    ...baseApi,
    resource: 'certificado',
    /** */
    getCertData(id, courseOrTrailId) {
        return certificateService
            .get(`Imprimir/${id}/${courseOrTrailId}`)
            .then(({ success, data }) => ({
            success,
            data: {
                ...data,
                course: data.curso?.itensDoCurso.map(item => ({
                    name: item.midia?.nome || item.avaliacao?.titulo,
                    duration: item.midia?.duracao || null,
                    type: item.tipo
                })),
                trail: data.formacao?.etapas.map(step => ({
                    name: step.nome,
                    duration: step?.duracao,
                    items: step?.itens?.map(item => ({
                        name: item.nome,
                        duration: item?.duracao,
                        type: item.tipo,
                        items: item?.itens?.map(subItem => ({
                            name: subItem.nome,
                            duration: subItem.duracao
                        })) || []
                    })) || []
                }))
            }
        }));
    }
};
class CertificadosService extends BaseService {
    constructor() {
        super({ resource: 'certificado' });
    }
    async getCertData(certificadoId, cursoFormacaoId, usuarioId) {
        return this.plantarApi.get(`${this.resource}/Imprimir/${certificadoId}/${cursoFormacaoId}/${usuarioId}`);
    }
    async imprimir(certificadoId, cursoFormacaoId) {
        return certificateService.getCertData(certificadoId, cursoFormacaoId);
    }
    async imprimirCertificadoUsuario(certificadoId, cursoFormacaoId, usuarioId) {
        const { data, success } = await this.plantarApi.get(`${this.resource}/Imprimir/${certificadoId}/${cursoFormacaoId}/${usuarioId}`);
        return { data, success };
    }
    async upload(obj) {
        const postData = new FormData();
        const keys = Object.keys(obj.data);
        for (let i = 0; i < keys.length; i++) {
            postData.append(keys[i], obj.data[keys[i]]);
        }
        try {
            const { data, success } = await this.plantarApi.post(`${this.resource}/FileUpload/${obj.certificadoId}/${obj.alias}`, postData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return { data, success, notifications: [] };
        }
        catch (error) {
            const { notifications, success } = error.response?.data || {};
            return {
                data: {},
                success,
                notifications
            };
        }
    }
}
const certificadosService = new CertificadosService();
export default certificadosService;
