import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStorage } from '@vueuse/core';
import { dialog, safeCopy, clamp, validateLink, openAchievement } from '@/common/util';
import midiasService from '@/services/midias.service';
import avaliacaoService from '@/services/avaliacao.service';
import { assessmentService } from '@/services/avaliacao.service';
import scormAcompanhamento from '@/services/scormAcompanhamento.service';
// Dicionarios
const typePt2En = {
    curso: 'course',
    formacao: 'formation',
    midia: 'mediaObject',
    avaliacao: 'test'
};
const typeEn2Pt = {
    course: 'curso',
    formation: 'formacao',
    mediaObject: 'midia',
    test: 'avaliacao'
};
const typeObj = {
    video: 'video',
    audio: 'audio',
    imagem: 'image',
    scorm: 'scorm',
    documento: 'document',
    outros: 'other',
    link: 'link',
    pdf: 'pdf',
    curso: 'course',
    avaliacao: 'test'
};
export const useSalaDeAulaStore = defineStore('salaDeAula', () => {
    const { t } = useI18n();
    const status = ref(0); // -1 erro de excessão, 0 - inicial, 1 - carregando, 2 - sucesso, 3 - erro não fatal
    const loadingList = ref([]);
    const type = ref(''); // 0 - curso, 1 - trilha, 2 - obj de midia
    const certificateEnabled = ref(false);
    const defaultInfo = {
        id: '',
        active: true,
        title: '',
        type: '',
        synopsis: '',
        description: '',
        requiredScore: 0,
        workload: 0,
        subscrebed: false,
        timeLimit: 0,
        sequential: false,
        required: false,
        progress: {
            completed: false,
            percent: 0
        },
        expired: false,
        dateExpiration: '',
        origin: '',
        reaction: '',
        totalReactions: 0,
        disableFastForward: false,
        inscription: {
            id: ''
        }
    };
    const defaultScormProgress = {
        id: '',
        userId: '',
        cursoId: '',
        formacaoId: '',
        midiaId: '',
        children: '',
        version: '',
        completionStatus: '',
        completionThreshold: '',
        credit: '',
        entry: '',
        exit: '',
        launchData: '',
        location: '',
        maxTimeAllowed: '',
        mode: '',
        progressMeasure: 0,
        scaledPassingScore: 0,
        scoreChildren: 0,
        scoreScaled: 0,
        scoreRaw: 0,
        scoreMin: 0,
        scoreMax: 0,
        sessionTime: '',
        successStatus: '',
        suspendData: '',
        timeLimitAction: '',
        totalTime: '',
        adlNavRequest: '',
        fullTime: '',
        suspendData1: '',
        scormScriptLink: ''
    };
    const info = ref({ ...safeCopy(defaultInfo) });
    // Lista (sequencial) de todos os items do curso/formação
    const items = ref([]);
    // Lista (sequencial) de Etapas da Formação
    const milestones = ref([]);
    // O item atual que o usuário estiver utilizando
    const activeItem = ref();
    // Avaliações
    const activeTest = useStorage('test-info', {
        id: '',
        title: '',
        observation: '',
        maxQuestions: 0,
        duration: 0,
        canRetry: '',
        maxRetries: 0,
        retries: 0,
        latestRetry: null,
        score: 0,
        type: 0,
        displayAnswers: false,
        showTimer: false,
        active: {
            id: '',
            finished: false,
            started: false,
            startTime: null,
            current: 0,
            questions: [],
            result: {
                approved: false,
                duration: 0,
                score: 0,
                hit: 0,
                miss: 0
            }
        }
    });
    // Scorm
    const scormProgress = reactive({ ...safeCopy(defaultScormProgress) });
    const testsResults = reactive([]);
    const getOverallProgress = computed(() => {
        let completed = 0;
        items.value?.forEach((item) => {
            if (item.type === 'test' && item.test?.result !== 'aprovado')
                return;
            if (item.progress.completed || item.progress.percent >= 100)
                completed++;
        });
        return (completed * 100 / (items.value.length || 1)) | 0;
    });
    const getMilestonesProgress = computed(() => milestones.value.map((ms) => {
        let completed = 0;
        let hasError = false;
        const msItem = items.value?.filter((item) => item.parent === ms.id) || [];
        msItem?.forEach((item) => {
            if (item.progress.completed || item.progress.percent >= 100)
                completed++;
            if (item.progress.completed && item.test.result && item.test.result !== 'aprovado')
                hasError = true;
        });
        return {
            percent: (completed * 100 / msItem.length) | 0,
            error: hasError
        };
    }));
    const loading = computed(() => status.value === 1);
    const getActive = computed(() => activeItem.value);
    const completeTest = computed(() => {
        const notId = !activeTest.value.active.id;
        const finished = activeTest.value.active.finished;
        return (notId || finished);
    });
    const itemsCompleted = computed(() => items.value.every(el => el.progress.completed));
    /**
     * Executa requisição a API e gera a estrutura adequada para utilização
     * @FIXME: ATENÇÃO
     * @FIXME: Devido as várias mudanças no backend tanto em CURSO quanto em FORMAÇÃO, vários ajustes tiveram que ser feito para manter a compatibilidade
     * @FIXME: O Código que deveria ser virtualmente igual (Curso e Formação), ficou completamente descaracterizado, e a R0FNQklBUlJB necessária para "sanar"
     * @FIXME: essas mudanças, triplicou o tamanho do código (e sua complexidade).
     * @TODO: A Melhor solução aqui (para limpeza do código) é separar curso e formação, em 2 SALAS DE AULA distintas, por mais que tenha que manter duas telas
     * @TODO: O Código atual torna a manutenção complexa
     * @param optType
     * @param id
     */
    const getInfo = async (optType, id) => {
        type.value = optType;
        items.value.splice(0, items.value.length);
        milestones.value.splice(0, milestones.value.length);
        try {
            status.value = 1;
            const { success, data } = await midiasService.getProgress(typeEn2Pt[optType], id);
            if (success) {
                const objType = data.progressoDoCurso?.curso || data.progressoDaFormacao?.formacao || data.progressoDaMidia?.midia || {};
                if (!objType?.id)
                    return status.value = 3;
                const approved = objType.inscricoes?.find(el => el.situacao === 'Aprovado') || false;
                const certificateId = objType?.certificadoId;
                // Verifico a condição de habilitar o certificado
                certificateEnabled.value = (Boolean(approved) && certificateId);
                Object.assign(info.value, {
                    id: objType.id,
                    active: objType.active,
                    title: objType.titulo,
                    type: optType,
                    synopsis: objType.sinopse,
                    description: objType.descricao,
                    requiredScore: objType.aproveitamentoRequerido,
                    workload: objType.cargaHoraria,
                    subscrebed: objType.inscrito,
                    timeLimit: objType.limiteDeDiasParaConclusao,
                    sequential: objType.sequenciaObrigatoria,
                    required: objType.obrigatorio,
                    certificateId: objType.certificadoId,
                    progress: {
                        completed: data.progressoGeral === 100,
                        percent: data.progressoGeral
                    },
                    expired: data.expirado,
                    dateExpiration: data.dataExpiracao,
                    origin: data.origem,
                    reaction: objType.reacao,
                    totalReactions: objType.totalReacoes,
                    disableFastForward: objType.bloquearAvanco,
                    termOfUse: {
                        id: objType.termoDeUsoId || null,
                        accepted: objType.usuarioAceitouTermoDeUso || false
                    },
                    inscription: {
                        id: objType.inscricoes?.[0].id
                    }
                });
                const fmtItem = (item, extra) => ({
                    id: item.id,
                    name: item.nome,
                    description: item.descricao,
                    coverImg: item.linkDaImagemDeCapa,
                    link: typeObj[`${item.tipo}`.toLowerCase()] === 'video'
                        ? `${item.pullzone}/${item.videoId}/playlist.m3u8`
                        : ((typeObj[`${item.tipo}`.toLowerCase()] === 'scorm' ? item.scormScriptLink : item.link || item.videoId || null)),
                    duration: item.duracao || item.avaliacaoDuracao,
                    progress: {
                        completed: item.finalizado || (`${item.tipo}`.toLowerCase() === 'avaliacao' && `${item.avaliacaoTipo}`.toLowerCase() !== 'reacao' && item.avaliacaoTentativasRealizadas > 0),
                        percent: item?.progresso?.percentualProgresso || 0
                    },
                    test: {
                        id: item.avaliacaoUsuarioId || null,
                        result: item.avaliacaoStatus || null,
                        type: item?.avaliacaoTipo ? `${item.avaliacaoTipo}`.toLowerCase() : null
                    },
                    allowDownload: item.permitirDownload || item.pdfMarcadAguaConfig?.allowPDFDownload || false,
                    watermarkRules: {
                        enabled: item.pdfMarcadAguaConfig?.includeWaterMarkInPDF || false,
                        username: item.pdfMarcadAguaConfig?.user.name || false,
                        email: item.pdfMarcadAguaConfig?.user.email || false,
                        company: item.pdfMarcadAguaConfig?.user.company || false,
                        unit: item.pdfMarcadAguaConfig?.user.unit || item.pdfMarcadAguaConfig?.user.businessUnit || false,
                        department: item.pdfMarcadAguaConfig?.user.department || false,
                        position: item.pdfMarcadAguaConfig?.user.position || item.pdfMarcadAguaConfig?.user.role || false,
                        dateTime: item.pdfMarcadAguaConfig?.user.dateTime || false,
                        ip: item.pdfMarcadAguaConfig?.user.ip || false
                    },
                    type: getTypeMedia(item),
                    videoId: item?.videoId,
                    pullzone: item?.pullzone,
                    reaction: item?.reacao,
                    totalReactions: item?.totalReacoes,
                    disableFastForward: item?.bloquearAvanco,
                    ...extra
                });
                // Cursos
                objType.itens?.forEach((item, index) => items.value.push(fmtItem(item, { index })));
                // Formações
                let etpItemIdx = 0;
                objType.etapas?.map((etapa) => {
                    milestones.value.push({
                        id: etapa.id,
                        name: etapa.nome,
                        description: etapa.descricao,
                        completed: etapa.finalizado,
                        // Formação Etapa -> (Objetos de midia || Avaliacao || Curso)
                        items: etapa.itens?.map((item) => {
                            const milestoneItemtype = typePt2En[`${item.tipo}`.toLowerCase()] || typePt2En.midia;
                            // Novo item para adicionar tanto no menuLateral quanto na listagem de items da sala de aula
                            const newItem = fmtItem(item, {
                                // O index serve para identificação do item (ativo) no menu lateral
                                index: milestoneItemtype !== 'course' ? etpItemIdx++ : null,
                                milestoneItemtype: milestoneItemtype,
                                description: item.sinopse || item.descricao || '',
                                parent: etapa.id
                            });
                            // Lista geral de todos os items (Objeto de midia e Avaliaçãox)
                            if (milestoneItemtype !== 'course')
                                items.value.push(newItem);
                            return {
                                ...newItem,
                                // Formação Etapa -> Curso -> (Objetos de midia || Avaliação)
                                items: item.itens?.map((subItem) => {
                                    const newSubItem = { ...fmtItem(subItem, { index: etpItemIdx++ }), courseId: item.id };
                                    items.value.push(newSubItem);
                                    return newSubItem;
                                })
                            };
                        })
                    });
                });
                status.value = 2;
            }
            else {
                status.value = 3;
            }
        }
        catch (err) {
            console.log(err);
            status.value = -1;
        }
    };
    const getTypeMedia = (media) => {
        //@TODO: Discutir a possibilidade do backend salvar esse tipo de midia como "externalLink"
        // para evitar complexidade no código do frontend
        if (media.tipo.toLowerCase() === 'link' && !validateLink.verify(media.link))
            return 'externalLink';
        return typeObj[`${media.tipo}`.toLowerCase()] || typeObj.outros;
    };
    const getInfoScorm = async (midiaId, query, trackProgress = true) => {
        try {
            const { data: progress } = await scormAcompanhamento.getProgressScorm(midiaId, query);
            if (progress)
                Object.assign(scormProgress, progress);
            else if (trackProgress) {
                const { data: progressCreated, success } = await scormAcompanhamento.post('', { midiaId, ...query });
                if (success)
                    Object.assign(scormProgress, progressCreated);
            }
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const sendScormProgress = async (data) => {
        try {
            Object.assign(scormProgress, { ...scormProgress, ...data, id: scormProgress.id });
            await scormAcompanhamento.put('', scormProgress);
        }
        catch {
            //
        }
    };
    const setActive = (index) => {
        activeItem.value = { ...items.value[index] } || null;
    };
    const getTestInfo = async (testId) => {
        testId = testId || activeItem.value?.id;
        if (!testId || loadingList.value.includes(`test-${testId}`))
            return;
        loadingList.value.push(`test-${testId}`);
        try {
            const { success, data } = await avaliacaoService.getInfo({
                courseId: info.value.type === "formation" ? activeItem.value.courseId : info.value.id,
                trailId: info.value.type === "formation" ? info.value.id : null,
                testId
            });
            if (success) {
                Object.assign(activeTest.value, data);
                return activeTest.value;
            }
        }
        catch (error) {
            //
        }
        finally {
            loadingList.value = loadingList.value.filter((el) => el !== `test-${testId}`);
        }
    };
    const startTest = async (testId) => {
        try {
            const { success, data } = await avaliacaoService.start({
                courseId: info.value.type === "formation" ? activeItem.value.courseId : info.value.id,
                trailId: info.value.type === "formation" ? info.value.id : null,
                testId: testId || activeTest.value.id
            });
            if (success) {
                activeTest.value.active.started = true;
                activeTest.value.active.id = data.id;
                const { success: questionSuccess } = await nextQuestion();
                activeTest.value.active.startTime = performance.now();
                if (!questionSuccess) {
                    activeTest.value.active.questions.push({
                        error: 'Não foi possível carregar a questão'
                    });
                }
                return {
                    success: questionSuccess
                };
            }
            return { success };
        }
        catch (error) {
            //
            return {
                success: false
            };
        }
    };
    const nextQuestion = async () => {
        try {
            const { success, data, notifications } = await avaliacaoService.nextQuestion(activeTest.value.active.id);
            if (success) {
                activeTest.value.active.questions.push({
                    id: data.id,
                    type: data.tipoDeQuestao?.toLowerCase(),
                    statement: data.enunciado,
                    attatchment: data.anexo,
                    options: data.alternativas
                        ?.sort((a, b) => a.ordem - b.ordem)
                        .map((alt) => ({
                        id: alt.id,
                        text: alt.descricao
                    })),
                    scale: data.escala,
                    feedback: {
                        show: data.exibirFeedBack,
                        hit: data.feedbackAcerto,
                        miss: data.feedbackErro
                    },
                    result: null,
                    correctAnswers: [],
                    userAnswers: [],
                    imageLink: data.anexo
                });
                activeTest.value.active.current++;
            }
            else {
                activeTest.value.active.questions.push({
                    error: 'Não foi possível carregar a questão'
                });
            }
            return { success, data, notifications };
        }
        catch (error) {
            return {
                ...error.response?.data
            };
        }
    };
    const submitAnswer = async (answer) => {
        try {
            const { success, data } = await avaliacaoService.answer(answer);
            if (success) {
                const question = activeTest.value.active.questions.find((question) => question.id === answer.questionId);
                if (question) {
                    question.correctAnswers = data.correctAnswers;
                    question.userAnswers = answer.answersId; // data.userAnswers @FIXME: substituir isso aqui, quando o backend enviar o dado corretamente
                    question.result = activeTest.value.type === 1 ? true : data.correct;
                    question.feedback.hit = data.hit;
                    question.feedback.miss = data.miss;
                    question.answerScale = data.scale;
                    question.answerDiscourse = data.answerDiscourse;
                    if (data.correct || answer.scale >= 1)
                        activeTest.value.active.result.hit++;
                    else
                        activeTest.value.active.result.miss++;
                }
            }
            if (success) {
                return data;
            }
        }
        catch (error) {
            //
        }
    };
    const getTestResult = async (userTestId) => {
        try {
            const { success, data } = await avaliacaoService.results(userTestId);
            if (success) {
                // Caso já exista, substitui o registro atual
                testsResults.push(data);
                activeItem.value.test.id = data.userTestId;
                activeItem.value.test.result = data.approved ? 'aprovado' : 'reprovado';
                if (data.courseCompleted)
                    certificateEnabled.value = true;
                // Finaliza a avaliação "temporariamente" para poder gerar o certificado no final
                // Sem ter que recarregar a tela
                const index = items.value.findIndex((el) => el.id === activeTest.value.id && el.courseId === activeItem.value.courseId);
                if (index >= 0) {
                    items.value[index].progress.completed = true;
                    activeTest.value.active.finished = true;
                }
            }
            return { success, data };
        }
        catch (error) {
            //
        }
    };
    const restartTest = async () => {
        const ctId = activeTest.value.id;
        await clearTest();
        await getTestInfo(ctId);
    };
    const giveUpTest = async (force) => {
        if (!force)
            return dialog.open({
                title: t('feedback.attention'),
                message: t('toasts.classRoom.giveUp'),
                complete: () => giveUpTest(true)
            });
        clearTest();
    };
    const resetActiveTest = () => {
        activeTest.value = {
            id: '',
            title: '',
            observation: '',
            maxQuestions: 0,
            duration: 0,
            canRetry: '',
            maxRetries: 0,
            retries: 0,
            latestRetry: null,
            score: 0,
            type: 0,
            displayAnswers: false,
            showTimer: false,
            active: {
                id: '',
                finished: false,
                started: false,
                startTime: null,
                current: 0,
                questions: [],
                result: {
                    approved: false,
                    duration: 0,
                    score: 0,
                    hit: 0,
                    miss: 0
                }
            }
        };
    };
    const clearInfoClassRoom = () => {
        type.value = '';
        items.value = [];
        info.value = { ...safeCopy(defaultInfo) };
        Object.assign(scormProgress, { ...safeCopy(defaultScormProgress) });
    };
    const clearTest = async (keepStatus) => {
        // Verifica e remove se existe um resultado de teste pra rota atual
        if (!keepStatus) {
            const idx = testsResults.findIndex((test) => test.userTestId === activeItem.value.test.id);
            if (idx > -1)
                testsResults.splice(idx, 1);
            if (activeItem.value.test) {
                activeItem.value.test.id = null;
                activeItem.value.test.result = null;
            }
        }
        const testId = activeTest.value.id;
        if (!testId)
            return;
        else {
            try {
                // @FIXME: O enpoint não retorna mensagem consistente caso erro ocorra, temporariamente tratando como se sempre tivesse sucesso
                await avaliacaoService.giveUp(testId);
                const sts = items.value.find((el) => el.id === testId);
                if (sts) {
                    sts.progress.completed = true;
                    sts.progress.percent = 0;
                    sts.test.result = 'desistencia';
                }
            }
            catch (error) {
                //
            }
        }
        resetActiveTest();
    };
    const checkInTest = () => {
        if (completeTest.value)
            return true;
        const message = activeTest.value.type !== 1
            ? t('toasts.classRoom.looseInProgress')
            : t('toasts.classRoom.notReturn');
        return new Promise((resolve) => {
            return dialog.open({
                title: t('feedback.attention'),
                message,
                complete: async () => {
                    try {
                        if (activeTest.value.type === 1)
                            await getTestResult(activeTest.value.active.id);
                        await clearTest();
                        return resolve(true);
                    }
                    catch (error) {
                        resolve(false);
                    }
                },
                cancel: () => resolve(false)
            });
        });
    };
    const setLocalMidiaProgress = (midiaId, progress) => {
        progress = clamp(progress, 0, 100);
        items.value.forEach((item) => {
            if (item.id !== midiaId)
                return;
            item.progress.percent = progress;
            if (!item.progress.completed)
                item.progress.completed = progress === 100;
        });
    };
    const setProgress = async (opts, progress) => {
        if (!opts.midiaId || (!opts.courseId && !opts.formationId))
            return;
        progress = clamp(progress, 0, 100);
        setLocalMidiaProgress(opts.midiaId, progress);
        try {
            const { success, data } = await midiasService.registerProgress(opts.midiaId, progress, {
                courseId: opts.courseId,
                formationId: opts.formationId,
                isClassroom: true
            });
            if (success && data?.aprovacao.status === 'Aprovado') {
                certificateEnabled.value = true;
            }
            if (data?.aprovacao?.conquistas) {
                data.aprovacao.conquistas?.map(c => {
                    openAchievement({
                        title: 'Parabéns!',
                        subtitle: c.regra,
                        points: c.pontuacao || 0
                    });
                });
            }
        }
        catch {
            //
        }
    };
    const dontAnswerTest = async () => {
        try {
            const { success } = await assessmentService.skipEvalution({
                courseId: info.value.type === 'formation' ? activeItem.value.courseId : info.value.id,
                trailId: info.value.type === 'formation' ? info.value.id : null,
                assessmentId: activeTest.value.id
            });
            if (success) {
                activeItem.value.progress.completed = true;
                activeItem.value.progress.percent = 100;
                activeItem.value.test.result = 'aprovado';
                // @GAMB: Verifica se após a desistencia, o usuário foi aprovado
                // @TODO: converter em função watch
                certificateEnabled.value = itemsCompleted.value;
            }
        }
        catch {
            //
        }
    };
    const getVideoInfo = async (videoId, params) => {
        try {
            const { data } = await midiasService.getVideoInfo(videoId, params);
            return data;
        }
        catch {
            //
        }
    };
    return {
        status,
        type,
        info,
        items,
        milestones,
        activeTest,
        activeItem,
        scormProgress,
        certificateEnabled,
        // Getters
        loading,
        getActive,
        getOverallProgress,
        getMilestonesProgress,
        completeTest,
        itemsCompleted,
        // Actions
        getInfo,
        getInfoScorm,
        getItem: (index) => items.value[index],
        setActive,
        setCertificateEnabled: (value) => certificateEnabled.value = Boolean(value),
        setProgress,
        setLocalMidiaProgress,
        // Avaliações
        testsResults,
        getTestInfo,
        startTest,
        nextQuestion,
        submitAnswer,
        getTestResult,
        clearTest,
        restartTest,
        giveUpTest,
        resetActiveTest,
        sendScormProgress,
        clearInfoClassRoom,
        checkInTest,
        dontAnswerTest,
        getVideoInfo
    };
});
