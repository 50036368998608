import { toast } from '@/common/util';
import { vistraBiApi } from '@/config/appAPIs';
import { useAuthStore } from '@/stores';
export default {
    async login() {
        const aliareApi = window.configuration?.aliare?.host;
        const clientId = window.configuration?.aliare?.credentials?.client_id || 'plantar-educacao';
        return fetch(`${aliareApi}/aliare-portal/api/Login?clientId=${clientId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Tenant': useAuthStore().getActiveTentant,
                'Authorization': `${useAuthStore().token.token_type} ${useAuthStore().token.access_token}`
            }
        })
            .then(response => response.json())
            .catch(error => {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao autenticar usuário no Vistra BI' });
            return {
                authToken: '',
                refreshToken: '',
                status: false
            };
        });
    },
    async setParamsRequest({ panelId, params }) {
        params = params || [];
        const biParams = [];
        params.forEach(el => {
            let value = el.value;
            if (el.type === 'metadata' && el.value === 'tenantId') {
                value = useAuthStore().getActiveTentant;
            }
            else if (el.type === 'metadata' && el.value === 'superiorId') {
                value = useAuthStore().persistentInfo.supervisor.id;
            }
            biParams.push({
                id: el.id,
                valor: value,
                descricao: el.description
            });
        });
        return vistraBiApi.post('/Integracao2/SetParametrosRequisicaoExterna', {
            painel: panelId,
            parametros: biParams
        });
    }
};
